<template>
    <div :class="[flag? '': 'content-box']">
        <div :class="[flag? '': 'container']">
            <div class="content"> 
            <div style="font-size:18px;margin:30px 0 15px">
                {{data.name}} 回访记录
            </div>
            <div class="listBox" v-for="item in interview" :key="item" >
                <div class="list_row">
                    <div class="flex">
                        <div><label for="">回访内容：</label>{{item.record}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">时间：</label>{{item.time}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">回访人：</label>{{item.uid}}</div>
                    </div>
                </div>
                 <div class="btn_con">
                    <a-button type="primary" size="small" ghost @click="showEditRecord(item)">编辑</a-button>
                    <a-popconfirm title="确定要删除吗?"  @confirm="() => delRecord(item.id)">
                        <a-button type="danger" size="small" ghost>删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
             <a-button  class="addBtn fixedBtn" size="large" type="primary" @click="showEditRecord()">添加回访</a-button>
           
            <a-modal v-model="visible" :centered="true" title="回访记录"  @ok="editRecord">
                <div class="addBox">
                    <div class="add_title">请输入内容</div>
                    <a-textarea class="add_inp" v-model="editText" :auto-size="{ minRows: 5, maxRows: 8 }" />
                </div>
            </a-modal>
           <!--  <div class="save"><a-button type="primary" @click="submit">保存</a-button></div> -->
            </div>
        </div>
        </div>
</template>
<script>
// import $ from "jquery";
import {isMobile,ajaxUrl,requestXml} from '../../../assets/js/request';
export default {
    data() {
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            data:{
                "name": "",
            },
            interview:{},  //回访记录
            onRecord:'', //修改中的回访记录
            editText:'',
            visible:false,
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        this.getDetail();
        this.getRecord();
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
        showEditRecord(item){
            this.visible=true;
            if(item){
                this.onRecord=item;
                this.editText=item.record;
            }else{
                 this.onRecord='';
                this.editText='';
            }
            
        },
        delRecord(id){
            requestXml("/jieb/Temporary/interviewdel","POST",() => {
                   this.getRecord();
                   this.onRecord='';
                   this.visible=false;
                   this.editText='';
            },{id:id})
        },
        editRecord(){
            let that = this;
               var time=new Date().getTime();
               let url='/jieb/Temporary/interviewadd';
               let data={temporaryid:this.id,record:this.editText,'time':Math.round(time/1000)
               }
               if(this.onRecord){
                   url="/jieb/Temporary/interviewedit",
                   data.id=this.onRecord.id
               }
               requestXml(url,"POST",() => {
                   that.getRecord();
                   this.onRecord='';
                   this.visible=false;
                   this.editText='';
                //    location.reload();
            },data);
        },
        getRecord(){
            let that = this;
            requestXml("/jieb/Temporary/tempbasedetails","POST",(res) => {
                // const list = [...res.interview];
                // that.interview=[];
            //    that.interview = Object.assign({},[...list]);
            // that.interview.push(...res.interview);
            that.interview=res.interview;
            //   this.$forceUpdate();
            },{id:this.id});
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Temporary/tempbasedit","GET",(res) => {
                this.data = Object.assign({}, res);
            },{id: this.id})
        },
    }
};
</script>
<style scoped>
    .content{
        min-height: 100vh;
    }
    .listBox{
        margin: 0 15px;
        margin-bottom: 10px;
        padding: 15px;
        border: 1px solid #ededed;
        border-radius: 5px;
    }
     .listBox .list_row .flex{
        justify-content: space-between;
        padding: 5px 0;
    }
   .minInput{
        width: 200px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .page_select {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #fff;
    }
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
     .fixedBtn{
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 999;
        height: 43px;
        border-radius: 50px;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }.a-inline-block{
        display: flex;
        align-items: center;
        height: 40px;

    }
    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  

</style>
